import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../../Common/Header';
import Footer from './../../Common/Footer';

import Banner from './../../Elements/Banner';
import ModalVideo from 'react-modal-video'
import '../../../../node_modules/react-modal-video/css/modal-video.min.css';
import { baseUrl } from '../../../config';


class Publicacion extends React.Component {
    
    constructor() {
        super()
        this.state = {
            publicaciones: [],
            banner: [],
            isOpen: false
        }
        
    }

    

    componentDidMount()
    {
        
        fetch(`${baseUrl}publicaciones`)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    publicaciones: response
                });
        })

        fetch(`${baseUrl}bannerinterna/proyectos`)
            .then(response => response.json())
            .then(response => {
                this.setState({banner: response});
                //console.log('llenado',this.state.socio.banners);
            })
    }
    
    render() {
        const {publicaciones, banner} = this.state;
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Publicaciones" pagename="Publicaciones" bgimage={banner.image}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                        {
                                publicaciones ?
                                <div className="portfolio-wrap___ mfp-gallery work-grid row clearfix">
                                {publicaciones?.map((item, index) => (
                                    <div key={index} className={` masonry-item col-md-4 col-sm-6 m-b30`}>
                                        <div className="image-effect-two hover-shadow">
                                            <img src={item.image} alt="" />
                                            <div className="figcaption">
                                                <h4 className="mt-tilte">{item.title}</h4>
                                                <p>{item.description}</p>
                                                <a href={item.tipo_archivo == 'normal' ? item.archivo : item.url} target="_blank" className="read-more site-button btn-effect">Descargar</a>
                                                
                                            </div>
                                            
                                        </div>
                                        <h5 className='text-center'>{item.title}</h5>
                                    </div>
                                ))}
                                
                            </div>
                            : ''
                            }
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                    
                </div>

                <Footer />
            </>
        );
    };
};

export default Publicacion;